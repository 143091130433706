.addprop-card {
  width: 100% !important;
  margin-top: 0px !important;
}

.fontsmaller {
  font-size: smaller;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
  border: 0px !important;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__suggests {
  list-style-type: none;
  margin-top: 10px !important;
  margin-left: 0px;
  padding-left: 5px;
  font-size: 14px !important;
  border: 1px solid #cccccc;
}

.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}

.center-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 2rem;
}

.center-address-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 2rem;
  margin-top: -30px;
}

.btnTime {
  padding-left: 0px;
}

.datetime-text {
  width: 100% !important;
}
