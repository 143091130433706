.toast-wrapper {
  background: var(--ion-color-primary) !important;
}

.toaststyle {
  background: var(--ion-color-primary) !important;
}

.pad-can {
  padding-right: 2rem;
  padding-bottom: 0.5rem;
}

.propertyCan {
  font-size: 1.4em;
  margin-top: 10px;
}

.pullright {
  margin-left: auto;
  padding-right: 50px !important;
  margin-bottom: auto;
}

.listpad {
  padding-bottom: 15px;
}

.zeropad {
  padding: 0px !important;
}
