.halfp {
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
  font-size: larger !important;
}

.dashImage {
  width: 100%;
  max-width: 160px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.dashRow {
  border-bottom: 1px solid #eee;
  padding: 0px 0px 0px 0px;
}

.dashstars {
  float: left;
}

.datacol {
  padding-left: 20px;
}

.tlDate {
  padding-left: 10px;
  font-weight: bold;
  width: 135px;
}

.tlTime {
  font-weight: bold;
  padding-right: 20px;
  font-size: larger !important;
}

.tlIconLabel {
  padding-right: 10px;
}

.iconpad {
  margin-right: 5px;
  width: 1.25em !important;
  height: 1.25em !important;
  vertical-align: bottom;
}

.iconpad1 {
  width: 1em !important;
  height: 1em !important;
}

.noleftpad {
  padding-left: 0px !important;
  --padding-start: 0px;
}
.iconpadleft {
  margin-left: 5px;
  width: 1.25em !important;
  height: 1.25em !important;
}

.iconlabel {
  font-size: 14px;
  font-weight: bold;
  padding-right: 40px;
}

.fright {
  text-align: center;
  margin-top: 0px;
  font-weight: 700;
  vertical-align: top;
}

.pcard {
  padding: 0px !important;
  z-index: 9;
  box-shadow: none !important;
}

.gridshadow {
  padding: 0px !important;
  margin: -4px 0px 15px 0px !important;
  z-index: 9;
  box-shadow: rgb(0 0 0 / 12%) 1px 8px 15px -3px !important;
}

.paddress {
  font-size: 14px;
  color: #737373;
}

.no-min-height {
  --min-height: 0px !important;
}

.cprops {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px #dddddd solid;
}
