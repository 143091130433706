.large {
  font-size: 2.2em !important;
  padding-right: 20px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted var(--ion-color-primary);
}

.tooltip .tooltiptext {
  width: 120px;
  background-color: var(--ion-color-primary);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent var(--ion-color-primary);
}
.tooltip:hover .tooltiptext {
  visibility: visible !important;
}
