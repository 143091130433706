.labelpad {
  padding-right: 0.4em;
  align-self: flex-end;
}

.person-icon {
  width: 0.75em;
  height: 0.75em;
  padding-right: 15px;
  color: gray;
}

.addlarge {
  font-size: 2em;
  padding-right: 20px;
  padding-bottom: 5px;
}

.pb-20 {
  padding-bottom: 10px;
}

.pl-30 {
  padding-left: 30px !important;
}
.pt-20 {
  padding-top: 20px !important;
}

.input {
  width: 100% !important;
}
