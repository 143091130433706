.hsTitle {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-top: 0.3em;
  font-size: 22px !important;
  font-weight: 600;
  text-align: center;
}

.hsIntro {
  padding-bottom: 0.3em;
  font-size: 17px !important;
  text-align: center;
}

.pad-top {
  padding-top: 15px;
}

.mdl-button--raised.mdl-button--colored {
  background: var(--ion-color-primary) !important;
  color: #fff !important;
}

.navTitle {
  padding-left: 20px !important;
}

.hs-logo {
  max-width: 125px;
  text-align: left !important;
}

.fontstrong {
  font-weight: bold;
  font-size: 14px;
}
