.starHeader {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 0.3em;
}

.starHeader-sm {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 0.3em;
  font-size: small;
}

.rangeslide {
  padding-left: 30px !important;
  padding-right: 30px !important;
  --height: 6px !important;
  --bar-background: lightgray !important;
  --knob-size: 20px;
  --knob-background: var(--ion-color-primary);
}

.smallText {
  font-size: smaller;
  justify-content: center;
}
