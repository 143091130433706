.brokeravatar {
  width: 100% !important;
  height: 100% !important;
  max-width: 75px !important;
  max-height: 75px !important;
}

.agent-content {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 1em;
}

.agent-logo {
  max-width: 150px;
  padding-top: 3em;
}

.agent-icons {
  font-size: 32px;
}

.col-center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.col-right {
  text-align: right;
}

.frmGrid {
  width: 90% !important;
}
