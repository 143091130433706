[class*="star-"] {
  font-size: 20px;
  margin-left: 0.15em;
  margin-right: 0.15em;
}

.star-on {
  color: var(--hs-star-color);
  visibility: inherit;
}

.star-off {
  color: lightgrey;
  visibility: inherit;
}

.centered {
  justify-content: space-evenly;
  padding: 0px !important;
  --min-height: 0px !important;
}

.titleCenter {
  text-align: center !important;
}
