.swiper-container {
  height: 100% !important;
}

.slideTitle {
  text-align: center;
}

.slideImg {
  max-width: 250px !important;
  min-width: 150px !important;
}

div.swiper-pagination {
  top: 20px;
  bottom: inherit;
}

.swiper-pagination-bullet-active {
  background-color: var(--ion-color-primary) !important;
}

p.introTitle {
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  color: var(--ion-color-primary);
}
p.introText {
  font-size: 0.8em;
  padding-left: 15%;
  padding-right: 15%;
  text-align: justify;
}
