.client-content {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 1em;
}

.agent-icons {
  font-size: 32px;
}

.col-center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.col-right {
  text-align: right;
}

.frmGrid {
  width: 90% !important;
}
